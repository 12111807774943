<template>
  <CardGrid :list="list" />
</template>

<script>
import CardGrid from '@/components/common/CardGrid'

export default {
  name: 'Dashboard',
  components: {
    CardGrid,
  },

  data() {
    return {
      list: [
        // {
        //   title: 'Content',
        //   subtitle: 'Manage page content',
        //   actionTxt: 'Manage',
        //   to: { name: 'settings.content' }
        // },
        {
          title: 'Privacy Policy',
          subtitle: 'Update the Privacy Policy',
          actionTxt: 'Modify',
          to: { name: 'settings.pages.privacy' },
        },
        {
          title: 'Terms of Service',
          subtitle: 'Update the Terms of service',
          actionTxt: 'Modify',
          to: { name: 'settings.pages.terms-of-service' },
        },
        {
          title: 'Version Control',
          subtitle: 'Manage mobile app versions',
          actionTxt: 'Manage',
          to: { name: 'settings.version-control' },
        },
      ],
    }
  },
}
</script>
